/*
 * @Descripttion:
 * @Author: panyufeng
 * @Date: 2024-09-29 15:27:43
 * @LastEditors: panyufeng
 * @LastEditTime: 2024-10-09 16:11:56
 */

import service from '@/utils/request'

// 列表数据查询
export const getList = (params: any) =>
  service.request({
    method: 'post',
    url: '/enterpriseViolationSetting/list',
    data: params
  })

// 违章转移api新增/编辑
export const updateSetting = (params: any) =>
  service.request({
    method: 'post',
    url: '/enterpriseViolationSetting/updateSetting',
    data: params
  })

// 违章转移修改状态
export const updateState = (params: any) =>
  service.request({
    method: 'post',
    url: '/enterpriseViolationSetting/updateState',
    data: params
  })
