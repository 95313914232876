/*
 * @Description: c-table 翻页方法
 * @Author: yaoxiaoxiao
 * @RearEnd:
 * @Date: 2021-04-09 14:01:03
 * @LastEditors: yaoxiaoxiao
 * @LastEditTime: 2021-04-09 15:41:04
 */

interface Result {
    pageCurrentChange: (val: number) => void;
    pageSizeChange: (val: number) => void;
}

interface Pagination {
    isShow: boolean;
    total: number;
    pageSize: number;
    currentPage: number;
    pageSizes: number[];
}

export function usePagination(pagination: Pagination, callback: Function): Result {
  /**
   * @description: table 翻页
   * @param {number} val
   * @return void
   */
  const pageCurrentChange = (val: number): void => {
    pagination.currentPage = val
    callback()
  }
  /**
    * @description: table 每页条数设置
    * @param {number} val
    * @return {void}
   */
  const pageSizeChange = (val: number): void => {
    // 设置条数前重置表格到第一页
    pagination.currentPage = 1
    pagination.pageSize = val
    callback()
  }

  return {
    pageCurrentChange,
    pageSizeChange
  }
}
