
import CSearchList from '@/components/c-search-list'
import CTable from '@/components/c-table'
import { getIndexConfig, getTableConfig, inputList } from './config'
import { usePagination } from '@/hooks/usePagination'
import { defineComponent, reactive, toRefs, onMounted } from 'vue'
import { getList, updateState } from './api'
import { toResult } from '@/utils/toResult'
import { btnName } from '@/utils'
// import CButtonGroup from '@/components/c-button-group'
import Edit from './edit.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
export default defineComponent({
  components: {
    // CButtonGroup,
    CSearchList,
    CTable,
    Edit,
  },
  setup() {
    const data = reactive({
      inputList: inputList(),
      ...getIndexConfig(),
      isType: '',
      isedit: false,
    })

    const tableConfig = reactive({
      ...getTableConfig(),
    })

    const getListFn = async () => {
      const p = {
        ...data.queryForm,
        currPage: tableConfig.pagination.currentPage,
        pageSize: tableConfig.pagination.pageSize,
      }

      tableConfig.loading = true
      const [err, res] = await toResult(getList(p))
      tableConfig.loading = false
      if (err) return
      tableConfig.data = res.list || []
      tableConfig.pagination.total = res.total
    }

    // 查询
    const handleSearch = (params) => {
      tableConfig.pagination.currentPage = 1
      params && (data.queryForm = { ...params })
      getListFn()
    }

    const onToggle = (name, row?) => {
      data[name] = !data[name]
      row && (data.rowData = row)
    }

    // 重置
    const handleReset = (val) => {
      data.queryForm = val
    }

    const { pageCurrentChange, pageSizeChange } = usePagination(
      tableConfig.pagination,
      () => {
        getListFn()
      }
    )

    // 新增、修改 按钮操作
    const btnHandle = (type, row) => {
      data.rowData = Object.assign({}, row)
      switch (type) {
        case 'edit': {
          data.isType = 'edit'
          onToggle('isedit', data.rowData)
          break
        }
        case 'add': {
          data.isType = 'add'
          onToggle('isedit', data.rowData)
          break
        }
        case 'state': {
          ElMessageBox.confirm('确定吗', '确认', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }).then(async () => {
            const p = {
              id: data.rowData.id,
              state: data.rowData.state === 1 ? 0 : 1,
            }
            await updateState(p)
            ElMessage({
              type: 'success',
              message: '成功',
            })
            handleSearch({})
          })

          break
        }
      }
    }

    onMounted(() => {
      handleSearch({})
    })

    return {
      ...toRefs(data),
      handleSearch,
      btnName,
      handleReset,
      tableConfig,
      pageCurrentChange,
      pageSizeChange,
      getListFn,
      onToggle,
      btnHandle,
    }
  },
})
