
import {
  defineComponent,
  reactive,
  toRefs,
  watchEffect,
  onMounted,
  ref,
} from 'vue'
import { required } from '@/utils/rules'
import { updateSetting } from './api'
import { ElMessage } from 'element-plus'
import { toResult } from '@/utils/toResult'
import moment from 'moment'

export default defineComponent({
  components: {},
  props: {
    modelValue: {
      type: Boolean,
      required: true,
      default: false,
    },
    row: {
      type: Object,
      default: () => {},
    },
    isType: {
      type: Object,
      default: () => {},
    },
  },
  // emits: ['update:modelValue'],
  setup(props, { emit }) {
    const data = reactive({
      visible: false,
      titlename: '违章转移设置',
      formRef: undefined,
      formData: ref<any>({}),
      id: 0,
      rules: {
        orgName: [required],
        code: [required],
        systemCode: [required],
        password: [required],
        secretKey: [required],
        apiDayCnt: [
          required,
          {
            validator: (rule, value, callback) => {
              const regex = /^[1-9][0-9]{0,4}$/
              if (!regex.test(value)) {
                callback(new Error('请输入1到99999之间的整数'))
              } else {
                callback()
              }
            },
            trigger: 'blur',
          },
        ],
        applyEndTime: [required],
        unitPrice: [
          required,
          {
            validator: (rule, value, callback) => {
              const regex =
                /^(?:[0-9]{1,2}(\.[0-9]+)?|[1-9][0-9]{2}(\.[0-9]+)?)$/
              if (!regex.test(value)) {
                callback(new Error('请输入0到999之间的数值'))
              } else {
                callback()
              }
            },
            trigger: 'blur',
          },
        ],
      },

      disabledDate(time) {
        // 选择的日期大于等于当前日期（包含今天）
        return time.getTime() < Date.now() - 8.64e7
        // 若选择的日期大于当前日期（不包含今天）： time.getTime() < Date.now()
        // 若选择的日期小于当前日期(不包含今天)： time.getTime() > Date.now() - 8.64e7
        // 若选择的日期小于等于当前日期（包含今天）： time.getTime() > Date.now()
      },
    })

    watchEffect(() => {
      data.visible = props.modelValue
    })

    /**
     * @description: 关闭dialog
     * @param {*} void
     * @return {*}
     */
    const onCancel = (): void => {
      data.visible = false
      emit('update:modelValue', false)
      emit('getListFn')
    }
    const init = () => {
      data.formData = props.row || []
      data.id = props.row.id || null
    }

    // 提交表单
    const handleSubmit = () => {
      data.formRef.validate(async (valid) => {
        if (valid) {
          const p: any = {
            ...data.formData,
            id: data.id || 0,
          }
          p.applyEndTime = moment(p.applyEndTime).format('YYYY-MM-DD HH:mm:ss')
          if (p.systemUseEndTime === undefined) {
            p.systemUseEndTime = null
          }
          if (p.systemUseEndTime !== null) {
            p.systemUseEndTime = moment(p.systemUseEndTime).format(
              'YYYY-MM-DD HH:mm:ss'
            )
          }

          const [err] = await toResult(updateSetting(p))
          if (err) return false
          ElMessage.success('操作成功')
          onCancel()
        }
      })
    }

    onMounted(() => {
      init()
    })
    return {
      ...toRefs(data),
      handleSubmit,
      onCancel,
      init,
    }
  },
})
