/*
 * @Description: 表单检验规则
 * @Author: yaoxiaoxiao
 * @RearEnd:
 * @Date: 2022-05-19 11:18:53
 * @LastEditors: yyx
 * @LastEditTime: 2023-04-26 13:53:30
 */

// 表单常规非空校验
export const required = {
  required: true,
  message: '必填',
  trigger: 'blur'
}

/**
 * @description: 长度限制检验
 * @param {number} num
 * @return {*}
 */
export const lengthLimit = (num: number) => ({
  len: num,
  message: `字符长度必须在${num}位`,
  trigger: ['blur', 'change']
})

/**
 * @description: 长度区间检验
 * @param {number} min
 * @param {number} max
 * @return {*}
 */
export const RangeLimit = (min: number, max: number) => ({
  min,
  max,
  message: `字符长度必须在${min}-${max}位之间`,
  trigger: ['blur', 'change']
})

// 大小写字母组合
export const capitalLetter = {
  pattern: /^[A-Za-z]+$/,
  message: '大小写字母',
  trigger: ['blur', 'change']
}

// 数字与大写字母组合
export const capitalLetterAndNumber = {
  pattern: /^[A-Z\d]+$/,
  message: '数字、大写字母组成',
  trigger: ['blur', 'change']
}

// 数字与字母组合
export const letterAndNumber = {
  pattern: /^[A-Za-z\d]+$/,
  message: '数字、字母（大小写）组成',
  trigger: ['blur', 'change']
}

// 只能输入中文
export const onlySimplified = {
  pattern: /^[\u4e00-\u9fa5]+$/,
  message: '只能输入中文汉字',
  trigger: ['blur', 'change']
}

// 除了中文汉字不能输入
export const xceptChinese = {
  pattern: /^[^\u4e00-\u9fa5]+$/,
  message: '不能输入中文汉字',
  trigger: ['blur', 'change']
}
// 不能有空格
export const noBlank = {
  pattern: /^\S*$/,
  message: '不能有空格',
  trigger: ['blur', 'change']
}

// 数字后面可以带2位小数点
export const numberAndDecimalPint = {
  pattern: /^(([1-9][0-9]*)|(0)|(([0]\.\d{0,2}|[1-9][0-9]*\.\d{0,2})))$/,
  message: '请输入数字且最多两位小数',
  trigger: ['blur', 'change']
}

// 校验车牌
export const carNo = {
  pattern: /[京津冀晋蒙辽吉黑沪苏浙皖闽赣鲁豫鄂湘粤桂琼川贵云渝藏陕甘青宁新使]{1}[A-Z]{1}[0-9a-zA-Z]{5,6}$/,
  message: '请输入正确车牌号',
  trigger: ['blur', 'change']
}
