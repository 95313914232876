import { ref } from '@vue/runtime-dom'

// 状态
export enum isAble {
  启用 = 0,
  禁用
}

export const getTableConfig = () => {
  // table 通用属性
  const common = {
    // table 有多选时设置
    isSelection: false,
    // table loading
    loading: false,
    // table 是否为斑马纹
    stripe: true,
    // table 的高度
    height: null,
    // table 的尺寸 medium / small / mini
    size: 'small',
    // table 是否开启索引
    isIndex: true,
    // table 索引 label
    indexLabel: '序号',
    // table 工具设置选项 配置则默认开启
    // tool: {
    //   // 列表本地储存name
    //   columnStorageName: 'driver-depot'
    // },
    tool: null,
    // table 数据
    data: []
  }

  // table 分页
  const pagination = {
    // 是否展示分页
    isShow: true,
    // 总条数
    total: 0,
    // 每页显示条目个数
    pageSize: 20,
    // 当前页数
    currentPage: 1,
    // 每页显示个数选择器的选项设置
    pageSizes: [10, 20, 40, 80, 100]
  }

  // table 列数据
  const column = [
    {
      key: 'orgName',
      label: '公司名称',
      width: '140'
    },
    {
      key: 'code',
      label: '单位证书编码',
      width: '140'
    },
    {
      key: 'systemCode',
      label: '系统代码',
      width: '140'
    },
    {
      key: 'apiDayCnt',
      label: 'API调用次数',
      width: '140'
    },
    {
      key: 'applyEndTime',
      label: '申请的截止日期',
      width: '140'
    },
    {
      key: 'systemUseEndTime',
      label: '系统功能截止日期',
      width: '140'
    },
    {
      key: 'state',
      label: '状态',
      slot: 'able',
      width: '140'
    },
    {
      key: 'action',
      label: '操作',
      width: '200',
      slot: 'action'
    }
  ]

  return {
    ...common,
    pagination,
    column
  }
}

// 搜索配置
export const inputList = () => [
  {
    type: 'input',
    label: '公司名称',
    key: 'orgName',
    placeholder: '请输入',
    labelWidth: 100,
    inputWidth: 200,
    maxlength: 24,
    showWordLimit: true
  },
  {
    type: 'select',
    label: '状态',
    key: 'state',
    placeholder: '请选择',
    labelWidth: 100,
    inputWidth: 200,
    options: [
      {
        label: '启用',
        value: 1
      },
      {
        label: '禁用',
        value: 0
      }
    ]
  }
]

// 页面搜索和基础变量
export const getIndexConfig = () => {
  // 页面基础变量
  const page = {
    queryForm: ref<any>({}), // 搜索参数
    visible: false,
    rowData: null,
    determine: false,
    title: ''
  }
  return {
    ...page
  }
}
